import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { MapContext, MarkerClusterer, Polyline } from '@react-google-maps/api';
import { getMarker } from '../../utils/api/site';
import SiteInfoWindow from '../SiteAdmin/SitesInMap/SiteInfoWindow';
import VamosMarker from './VamosMarker';
import { RoutesContext } from '../../context';
import groupMarker from './groupMarker.png';

const SitesInMarkers = ({
  sitesArray, addStop, setBoundsChanged, isButtonClicked, setButtonClicked, shouldContainDirections,
}) => {
  const mapContext = React.useContext(MapContext);
  const routesContext = React.useContext(RoutesContext);
  const markersRef = React.useRef([]);
  const [currentZoom, setCurrentZoom] = useState(mapContext ? mapContext.getZoom() : 0);
  const [visibleSites, setVisibleSites] = useState([]);
  const [sitesInBounds, setSitesInBounds] = useState([]);

  const clustererOptions = {
    styles: [
      {
        url: groupMarker,
        filter: 'hue-rotate(150deg)',
        height: 35,
        width: 35,
        textColor: '#FFFFFF',
        textSize: 12,
      },
      {
        url: groupMarker,
        height: 40,
        width: 40,
        textColor: '#FFFFFF',
        textSize: 12,
      },
      {
        url: groupMarker,
        height: 45,
        width: 45,
        textColor: '#FFFFFF',
        textSize: 12,
      },
    ],
  };

  const getCoordsFromObj = (obj) => {
    let latitude;
    let longitude;
    if (obj.coords) {
      latitude = obj.coords.lat;
      longitude = obj.coords.lng;
    } else if (obj.location.latitude && obj.location.longitude) {
      latitude = obj.location.latitude;
      longitude = obj.location.longitude;
    }
    return [latitude, longitude];
  };
  // This offset help us to show overlapped markers with zoom varations, in path mode for routes page.
  const getZoomOffset = () => {
    if (currentZoom > 7) return 0.2 * 2 ** (23 - currentZoom);
    return 10000;
  };
  // Add offset to coordinates if they are the same
  const addOffsetToCoords = (latitude, longitude, index) => {
    // Convert index to angle in degrees
    const angle = (index * 360) / (2 * Math.PI);
    // Constant offset distance. If we are in path mode for routes we add an extra ZoomOffset
    const offsetDistance = 0.000028 * getZoomOffset();

    // Calculate offset in radians
    const radianAngle = angle * (Math.PI / 180);

    // Calculate new offset latitude and longitude
    const offsetLatitude = latitude + offsetDistance * Math.cos(radianAngle);
    const offsetLongitude = longitude + offsetDistance * Math.sin(radianAngle);

    return [offsetLatitude, offsetLongitude];
  };

  const uniqueCoords = {};

  const getFormatedSiteData = (site) => {
    const [latitude, longitude] = getCoordsFromObj(site);
    let [offsetLatitude, offsetLongitude] = [];
    const key = `${latitude}-${longitude}`;

    if (key in uniqueCoords && currentZoom > 15) {
      uniqueCoords[key] += 1;
      [offsetLatitude, offsetLongitude] = addOffsetToCoords(latitude, longitude, uniqueCoords[key]);
    } else {
      uniqueCoords[key] = 0;
    }

    let siteId;
    if (site.site_id) {
      siteId = site.site_id;
    } else {
      siteId = site.id;
    }

    const siteMarkerIcon = getMarker({
      priority: site.priority, status: site.status, position: site?.position, icon: site.icon,
    });

    return [latitude, longitude, offsetLatitude, offsetLongitude, siteId, siteMarkerIcon];
  };

  useEffect(() => {
    let boundsListener;
    if (mapContext) {
      const updateSitesInBounds = () => {
        const bounds = mapContext.getBounds();
        if (!bounds) return;
        const filteredSites = sitesArray.filter(site => {
          const [latitude, longitude] = getCoordsFromObj(site);
          const siteLatLng = new window.google.maps.LatLng(latitude, longitude);
          return bounds.contains(siteLatLng);
        });
        setSitesInBounds(filteredSites);

        if (typeof setBoundsChanged === 'function') {
          setBoundsChanged(true);
        }
      };

      const initialBounds = mapContext.getBounds();
      if (initialBounds) {
        const initialVisibleSites = sitesArray.filter(site => {
          const [latitude, longitude] = getCoordsFromObj(site);
          const siteLatLng = new window.google.maps.LatLng(latitude, longitude);
          return initialBounds.contains(siteLatLng);
        });
        setVisibleSites(initialVisibleSites.length > 0 ? initialVisibleSites : sitesArray);
        setSitesInBounds(initialVisibleSites);
      } else {
        setVisibleSites(sitesArray);
      }

      boundsListener = mapContext.addListener('idle', () => {
        updateSitesInBounds();
      });
    }
    return () => boundsListener?.remove();
  }, [mapContext, sitesArray, setBoundsChanged]);

  useEffect(() => {
    if (routesContext) {
      routesContext.setVamosMarkersRefs(markersRef);
    }
  }, [routesContext]);

  useEffect(() => {
    if (isButtonClicked) {
      setVisibleSites(sitesInBounds);
      setButtonClicked(false);
      setBoundsChanged(false);
    }
  }, [isButtonClicked, setButtonClicked, setBoundsChanged, sitesInBounds]);

  useEffect(() => {
    if (mapContext) {
      const updateZoom = () => setCurrentZoom(mapContext.getZoom());
      const listener = mapContext.addListener('zoom_changed', updateZoom);

      // Clean up the event listener
      return () => {
        listener.remove();
      };
    }
    return undefined;
  }, [mapContext]);

  return (
    <>
      {visibleSites.length && (
        <MarkerClusterer options={clustererOptions} minimumClusterSize={shouldContainDirections ? 11 : 2} maxZoom={15}>
          {clusterer => sitesArray.map(site => {
            const [latitude, longitude, offsetLatitude, offsetLongitude, siteId, siteMarkerIcon] = getFormatedSiteData(site);
            return (
              <React.Fragment key={siteId}>
                <VamosMarker
                  key={`${site.id}`}
                  siteId={siteId}
                  ref={el => { markersRef.current[siteId] = el; }}
                  lat={offsetLatitude || latitude}
                  lng={offsetLongitude || longitude}
                  icon={siteMarkerIcon}
                  clusterer={clusterer}
                >
                  <SiteInfoWindow
                    site={site}
                    addStop={addStop}
                  />
                </VamosMarker>
                {((offsetLatitude && currentZoom > 15) || shouldContainDirections) && (
                <Polyline
                  path={[{ lat: latitude, lng: longitude }, { lat: offsetLatitude, lng: offsetLongitude }]}
                  options={{ strokeColor: '#40B870', strokeOpacity: 0.6, strokeWeight: 2 }}
                />
                )}
              </React.Fragment>
            );
          })}
        </MarkerClusterer>
      )}
    </>
  );
};

SitesInMarkers.defaultProps = {
  sitesArray: [],
  addStop: undefined,
  setBoundsChanged: () => {},
  shouldContainDirections: false,
};

SitesInMarkers.propTypes = {
  sitesArray: PropTypes.array,
  addStop: PropTypes.func,
  setBoundsChanged: PropTypes.func,
  setButtonClicked: PropTypes.func.isRequired,
  isButtonClicked: PropTypes.bool.isRequired,
  shouldContainDirections: PropTypes.bool,
};

export default SitesInMarkers;
