/**
 * Component to display a single marker into a map. This component needs to be pass
 * as a child of VamosMap component.
 * This components received a child to be used as a InfoWindow (it is not required)
 */
import React from 'react';
import PropTypes from 'prop-types';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { useVamosMap } from '../../../hooks';

const VamosMarker = React.forwardRef(({
  siteId,
  lat,
  lng,
  icon,
  children,
  isDraggable,
  onDragEnd,
  clusterer,
}, ref) => {
  // Hook VamosMap
  const { selectedMarker, setSelectedMarker } = useVamosMap();

  /**
   * Handle marker click event and pass
   * site id to the setSelectedMarker function.
   * This will allow to open just one selected InfoWindow
   * @param {*} id site id
   */
  const handleSelectedMarker = (id) => {
    if (id) {
      setSelectedMarker({ siteId: id });
    } else {
      setSelectedMarker(undefined);
    }
  };

  // When we work on routes we use labels to show the order of the stops.
  const iconData = icon.label ? { label: icon.label, icon: icon.icon } : { icon };
  return (
    <Marker
      ref={ref}
      position={{ lat, lng }}
      {...iconData}
      onClick={() => handleSelectedMarker(siteId)}
      draggable={isDraggable}
      onDragEnd={onDragEnd}
      clusterer={clusterer}
    >
      {children && selectedMarker && selectedMarker.siteId === siteId && (
        <InfoWindow
          position={{ lat, lng }}
          onCloseClick={handleSelectedMarker}
        >
          {children}
        </InfoWindow>
      )}
    </Marker>
  );
});

VamosMarker.displayName = 'VamosMarker';

VamosMarker.defaultProps = {
  icon: undefined,
  children: undefined,
  isDraggable: false,
  onDragEnd: undefined,
};

VamosMarker.propTypes = {
  siteId: PropTypes.number.isRequired,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  icon: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  children: PropTypes.element,
  isDraggable: PropTypes.bool,
  onDragEnd: PropTypes.func,
  clusterer: PropTypes.object.isRequired,
};

export default VamosMarker;
